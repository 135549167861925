<template>
  <a-modal :getContainer='getContainer' v-model="visible" :title="initData ? '编辑分组' : '添加分组'" width="500px" :maskClosable="false" :confirm-loading="confirmLoading" :afterClose="handleAfterClose" @ok="handleOk">
    <a-form-model ref="formModelRef" :model="form" :rules="rules" v-bind="formItemLayout">
      <a-form-model-item label="分组名称" prop="groupName" extra="建议使用四个字命名">
        <a-input v-model="form.groupName" placeholder="请输入" :disabled="isDefault" />
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="seqNum">
        <a-input v-model="form.seqNum" placeholder="请输入" :disabled="isDefault" />
      </a-form-model-item>
      <a-form-model-item label="分组图片" prop="groupImg" extra="* 建议尺寸750像素x160像素">
        <div :class="['img-box', {'show-delete': groupImgFull}]">
          <img :src="groupImgFull || require('../../assets/group-img-default.png')" alt="">
          <a href="javascript:;" class="btn-del" @click="form.groupImg = ''">
            <a-icon type="close" />
          </a>
        </div>
        <a-button class="btn-add" @click='showSelectImg = true' type="primary" :loading="uploading">
          替换图片
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <select-img-dialog idName='group-id' v-if='showSelectImg' :show='showSelectImg' @change='handleSelectDone' :initSelectData='[form.groupImg]'></select-img-dialog>
  </a-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { shop } from '@/api'

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 }
}

export default {
  name: 'ModalTagsEdit',

  props: {
    show: Boolean,
    initData: [Object, String],
    idName: String
  },

  setup (props, { root, emit }) {
    const showSelectImg = ref(false)
    const visible = computed({
      get: () => props.show,
      set: (val) => {
        emit('update:show', val)
      }
    })
    watch(
      () => visible.value,
      (data) => {
        if (data && props.initData) {
          Object.keys(form.value).forEach((x) => {
            form.value[x] = props.initData[x]
          })
          if (props.initData.seqNum === -1) {
            rules.value.seqNum = [{ required: true, message: '请输入' }]
          } else {
            rules.value.seqNum = [
              { required: true, message: '请输入' },
              { pattern: /^(0|[1-9][0-9]*)$/, message: '请输入大于等于0的整数' }
            ]
          }
        }
      }
    )

    const formModelRef = ref(null)
    const form = ref({
      groupName: '',
      seqNum: '',
      groupImg: ''
    })
    const groupImgFull = computed(() =>
      form.value.groupImg ? `${process.env.VUE_APP_IMG}${form.value.groupImg}` : ''
    )
    const rules = ref({
      groupName: [
        { required: true, message: '请输入' },
        { max: 12, message: '请输入简短一些' }
      ],
      seqNum: [
        { required: true, message: '请输入' },
        { pattern: /^(0|[1-9][0-9]*)$/, message: '请输入大于等于0的整数' }
      ]
    })
    const isDefault = computed(() => Boolean(props.initData && props.initData.seqNum === -1))

    const uploading = ref(false)
    function handleSelectDone (e) {
      form.value.groupImg = e[0]
      showSelectImg.value = false
    }

    const confirmLoading = ref(false)
    function handleOk () {
      formModelRef.value.validate(async (valid) => {
        if (!valid) return false
        try {
          confirmLoading.value = true
          const { code } = await shop.addProductGroup({
            ...form.value,
            ...(props.initData ? { productGroupId: props.initData.productGroupId } : {})
          })
          confirmLoading.value = false
          if (code !== '00000') return root.$message.error('提交失败，请稍后重试')
          root.$message.success(`${props.initData ? '修改' : '添加'}成功`)
          visible.value = false
          emit('update')
        } catch (e) {
          console.error(e.message || '提交失败')
        }
      })
    }

    function handleAfterClose () {
      Object.keys(form.value).forEach((x) => {
        form.value[x] = ''
      })
      formModelRef.value.clearValidate()
    }
    function getContainer () {
      return document.getElementById(props.idName)
    }
    return {
      showSelectImg,
      formItemLayout,
      visible,
      formModelRef,
      form,
      groupImgFull,
      rules,
      isDefault,
      uploading,
      confirmLoading,
      handleOk,
      handleAfterClose,
      handleSelectDone,
      getContainer
    }
  }
}
</script>

<style lang="less" scoped>
.img-box {
  position: relative;
  width: 301px;
  border-radius: 13px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
  .btn-del {
    display: none;
    position: absolute;
    z-index: 2;
    top: 2px;
    right: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 16px;
    background: hsla(0, 0%, 60%, 0.6);
    border-radius: 10px;
    ::v-deep .anticon {
      position: relative;
      top: 0;
      right: 0;
      color: #fff;
      font-size: 12px;
      line-height: 0;
    }
    &:hover {
      background: #333;
    }
  }
  &.show-delete:hover .btn-del {
    display: flex;
  }
}
</style>
