import { ref, watch, reactive, isRef, onMounted } from '@vue/composition-api'

export function useTableList (apiCall, queryRef, splitPage = true, dataHandler = x => x) {
  const indexRef = ref(0)
  const refresh = () => {
    indexRef.value++
  }

  const loadingRef = ref(false)

  const dataRef = ref([])
  const pageRtv = reactive({ current: 1, size: 10, total: 0 })

  let deps = [indexRef, () => pageRtv.current, () => pageRtv.size]
  let hasQuery = queryRef && isRef(queryRef)
  if (hasQuery) deps.push(queryRef)
  if (isRef(apiCall)) deps.push(apiCall)
  watch(deps, async (v, ov, onCleanup) => {
    try {
      if (loadingRef.value) return
      let cancel = false
      onCleanup(() => {
        cancel = true
      })
      loadingRef.value = true
      const { total, ...pageQuery } = pageRtv
      let realApiCall = isRef(apiCall) ? apiCall.value : apiCall
      const { code, msg, data, page } = await realApiCall({
        ...(hasQuery ? queryRef.value : {}),
        ...(splitPage ? pageQuery : {})
      })
      loadingRef.value = false
      if (code !== '00000') throw new Error(msg)
      if (cancel) return
      dataRef.value = dataHandler(data)
      if (page) pageRtv.total = page.total
    } catch (error) {
      console.error(error)
    }
  }, {
    immediate: true
  })

  function setPage (value) {
    pageRtv.current = value
  }

  function resetPage () {
    pageRtv.current = 1
  }

  return {
    refresh,
    dataRef,
    loadingRef,
    pageRtv,
    setPage,
    resetPage
  }
}

export function useFetch (fetcher, initial = null, deps) {
  const data = ref(initial)
  const loading = ref(false)
  const error = ref(null)
  onMounted(() => {
    watch(deps, (v, ov, onCleanup) => {
      let cancel = false
      onCleanup(() => {
        cancel = true
      })
      loading.value = true
      fetcher().then(res => {
        if (!cancel) {
          data.value = res
        }
      }, e => {
        if (!cancel) {
          error.value = e
        }
      }).then(() => {
        loading.value = false
      })
    }, {
      immediate: true
    })
  })
  return {
    data,
    error,
    loading
  }
}
